import ProcessProvider from "./processContext";
import ValidationProvider from "./validationContexts";
import UserProvider from "./userContext";
import { GlobalRouter } from "../routes/globalRoutes";
import SimulationProvider from "./simulationContext";
export const GlobalContext = ({ children }) => {
  return (
    <ValidationProvider>
      <SimulationProvider>
        <ProcessProvider>
          <UserProvider>
            <GlobalRouter></GlobalRouter>
          </UserProvider>
        </ProcessProvider>
      </SimulationProvider>
    </ValidationProvider>
  );
};
