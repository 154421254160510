import { formatDate } from "../../common/utils/format/formatDate";
import { formatMoney } from "../../common/utils/format/formatMoney";
import { getAge } from "../../common/utils/getAge";

const calculateMonthsUntil80 = (birthDateString) => {
  const [day, month, year] = birthDateString.split('/').map(Number);
  const birthDate = new Date(year, month - 1, day);
  const targetDate = new Date(birthDate);
  targetDate.setFullYear(targetDate.getFullYear() + 80);
  const currentDate = new Date();

  let months;
  months = (targetDate.getFullYear() - currentDate.getFullYear()) * 12;
  months -= currentDate.getMonth();
  months += targetDate.getMonth();
  if (currentDate.getDate() > targetDate.getDate()) {
    months -= 1;
  }
  return months;
}

export const validate = async (schemaValues, validationFI) => {
  let errors = {};
  /* set up content for validate */


  let loanValueFormatted = String(schemaValues.loanValue)
    .replace("R$", "")
    .replaceAll(".", "")
    .replace(",", ".");

  let propertyValueFormatted = String(schemaValues.propertyValue)
    .replace("R$", "")
    .replaceAll(".", "")
    .replace(",", ".");

  let maxLoanValue = propertyValueFormatted * validationFI.maxLoanPercent;
  
  propertyValueFormatted = Number(propertyValueFormatted);
  loanValueFormatted = Number(loanValueFormatted);
  
  let ageInNumber = schemaValues.birthDate;
  
  if (ageInNumber.includes("/")) {
    ageInNumber = getAge(formatDate(ageInNumber));
  } else {
    ageInNumber = getAge(ageInNumber);
  }

  let months = Number(schemaValues.timeMonths);

  /* Validations  */
  if (!schemaValues.loanValue) {
    errors.loanValue = "Esse campo é obrigátorio";
  }

  if (!schemaValues.propertyValue) {
    errors.propertyValue = "esse campo é obrigátorio";
  }

  if (!schemaValues.timeMonths) {
    errors.timeMonths = "esse campo é obrigátorio";
  }

  if (!schemaValues.birthDate) {
    errors.birthDate = "esse campo é obrigátorio";
  }

  if (propertyValueFormatted < validationFI.minGoodPrice) {
    const formatted = formatMoney(validationFI.minGoodPrice);
    errors.propertyValue = `O valor mínimo exigido é ${formatted}`;
  }

  if (propertyValueFormatted > validationFI.maxGoodPrice) {
    const formatted = formatMoney(validationFI.maxGoodPrice);
    errors.propertyValue = `O valor mínimo exigido é ${formatted}`;
  }

  if (loanValueFormatted > maxLoanValue) {
    const formatted = formatMoney(validationFI.maxGoodPrice);
    errors.loanValue = `Valor acima de ${
      validationFI.maxLoanPercent * 100
    }% do imovel, valor máximo ${formatted}`;
  }

  if (loanValueFormatted < validationFI.minLoanValue) {
    const formatted = formatMoney(validationFI.minLoanValue);
    errors.loanValue = `mínimo de ${formatted}`;
  }

  if (ageInNumber < 18) {
    errors.birthDate = "A idade mínima é de 18 anos";
  }

  if (ageInNumber > 79) {
    errors.birthDate = "A idade máxima é de 79 anos";
  }

  if (isNaN(ageInNumber)) {
    errors.birthDate = "Data inválida (dd/mm/yyyy)";
  }
  if (schemaValues.birthDate.length < 10) {
    errors.birthDate = "Data inválida (dd/mm/yyyy)";
  }

  let faltaTo80 = calculateMonthsUntil80(schemaValues.birthDate)
  let maxMesesToFinance = Math.min(validationFI.maxTimeMonths, faltaTo80)

  if (months > maxMesesToFinance) {
    errors.timeMonths = `Conforme data informada, o prazo máximo é de ${maxMesesToFinance} meses`;
  }

  if (months < validationFI.minTimeMonths) {
    errors.timeMonths = `mínimo de ${validationFI.minTimeMonths} meses`;
  }

  return errors;
};
