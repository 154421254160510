import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import { ProcessDetail } from "../process/resultadoDetalhado";
import { SimulationForm } from "../simulation/home";
import { AnimatePresence } from "framer-motion";
import { Process } from "../process/resultadoSimplificado";
import { NotFoundPage } from "../common/components/notFound/notFound";

export const GlobalRouter = () => {
  return (
    <Router>
      <AnimatePresence mode="wait">
        <Switch location={window.location} key={window.location.pathname}>
          <Route exact path="/" component={SimulationForm} />
          <Route path="/process/:process_id" component={ProcessDetail} />
          <Route path="/process/" component={Process} />
          <Route path="*" component={NotFoundPage}/>
        </Switch>
      </AnimatePresence>
    </Router>
  );
};
