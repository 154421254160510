import { createContext, useState } from "react";

export const ProcessContext = createContext([]);

const ProcessProvider = ({ children }) => {
  const [processList, setProcessList] = useState([]);

  return (
    <ProcessContext.Provider value={{ processList, setProcessList }}>
      {children}
    </ProcessContext.Provider>
  );
};
export default ProcessProvider;
