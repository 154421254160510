import { useFormik } from 'formik'
import { phoneMask } from '../../utils/phoneMaskInput'
import { userValidate } from '../../validation/userInfo'
import { onSubmitUserInfo } from './submitUserInfo'
import { Modal } from 'react-bootstrap'
import { ErrorMessage } from '../errorMessage'
import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'

export const ModalUserInfo = ({
  showModalUser,
  setShowModalUser,
  id,
  processList,
  user,
  productType,
  parameters = ''
}) => {
  const [inputName, setInputName] = useState('')
  const [inputEmail, setInputEmail] = useState('')
  const [inputPhonenumber, setInputPhonenumber] = useState('')

  const closeModal = () => {
    return setShowModalUser(false)
  }

  const history = useHistory()

  useEffect(() => {
    if (user) {
      closeModal()
      history.push(`/process/${id}`)
    }
  }, [])

  const formik = useFormik({
    initialValues: {
      name: inputName,
      phonenumber: inputPhonenumber,
      email: inputEmail,
    },
    validate: userValidate,
    onSubmit: (values) => {
      onSubmitUserInfo(values, history, id, processList, parameters, productType)
    },
  })

  return (
    <Modal
      show={showModalUser}
      backdropClassName="dropModal"
      onHide={closeModal}
    >
      <button
        type="button"
        className="position-absolute top-0 start-5 close-modal"
        onClick={closeModal}
      >
        x
      </button>

      <form
        autoComplete="off"
        className="text-center"
        onSubmit={(e) => {
          formik.handleSubmit(e)
        }}
      >
        <h3 className='mb-3'>Preencha os dados...</h3>
        <div className="w-75 mb-4 mx-auto text-center" style={{ marginTop: '20px' }}>
          <label className='fw-bold'>Seu nome completo:</label>
          <input
            className={`form-control mt-1 p-3 w-100 bg-none rounded-pill ${formik.errors.price ? 'input-error' : ''
              }`}
            name="name"

            onChange={(e) => {
              setInputName(e.target.value)
              formik.handleChange(e)
            }}
            value={inputName}
          />
          {formik.errors.name && (
            <ErrorMessage>{formik.errors.name}</ErrorMessage>
          )}
        </div>
        <div className="w-75 mb-4 mx-auto text-center">
          <label className='fw-bold'>Seu melhor email:</label>
          <input
            className={`form-control mt-1 p-3 w-100 bg-none rounded-pill ${formik.errors.price ? 'input-error' : ''
              }`}
            name="email"

            onChange={(e) => {
              setInputEmail(e.target.value)
              formik.handleChange(e)
            }}
            value={inputEmail}
          />
          {formik.errors.email && (
            <ErrorMessage>{formik.errors.email}</ErrorMessage>
          )}
        </div>
        <div className="w-75 mb-4 mx-auto text-center">
          <label className='fw-bold'>Telefone para contato:</label>
          <input
            className={`form-control mt-1 p-3 w-100 bg-none rounded-pill ${formik.errors.price ? 'input-error' : ''
              }`}
            name="phonenumber"

            value={inputPhonenumber}
            maxLength="15"
            onChange={(e) => {
              const num = phoneMask(e)
              setInputPhonenumber(e.target.value)
              formik.handleChange(phoneMask(e))
            }}
          />
          {formik.errors.phonenumber && (
            <ErrorMessage>{formik.errors.phonenumber}</ErrorMessage>
          )}
        </div>

        <button type="submit" className="btn btn-danger btn-custom w-75 mb-4 mx-auto mt-3 rounded-pill py-2 px-5 ">
          Continuar
        </button>

        <div className="font-weight text-center p-3 mt-auto" style={{ fontSize: '14px', fontWeight: 'bold' }}>
          Os dados serão utilizados somente para o envio das simulaçoes
        </div>
      </form>
    </Modal>
  )
}
