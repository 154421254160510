import { useContext, useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import { ProcessContext } from '../../../context/processContext'
import { formatMoney } from '../../utils/format/formatMoney'
export const MinInstallment = () => {
  const { processList } = useContext(ProcessContext)

  const [smallerInstallment, setSmallerInstallment] = useState({})
  const [propertyValue, setPropertyValue] = useState('')
  const [minValue, setMinValue] = useState('')

  useEffect(() => {
    function getMinInstallment(arrInstallments) {
      return arrInstallments.reduce((prev, curr) =>
        prev.installment < curr.installment ? prev : curr
      )
    }
    // find smaller installment to update state object

    processList.forEach((process) => {
      const minInstallment = getMinInstallment(process.installments)
      if (Object.keys(smallerInstallment).length === 0) {
        setSmallerInstallment(process)
      }
      if (smallerInstallment.installment > minInstallment) {
        setSmallerInstallment(process)
      }
    })
  }, [])

  useEffect(() => {
    if (Object.keys(smallerInstallment).length > 0) {
      setMinValue(formatMoney(smallerInstallment.installments[0].installment))
      let localSimulationFI = JSON.parse(localStorage.getItem('@SimulationFI'))
      let localSimulationCGI = JSON.parse(
        localStorage.getItem('@SimulationCGI')
      )
      let lastSimulation = localStorage.getItem('@lastSimulation')

      if (Number(lastSimulation) === 1 && localSimulationFI) {
        setPropertyValue(
          localSimulationFI.propertyValue.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })
        )
      } else {
        setPropertyValue(
          localSimulationCGI.propertyValue.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })
        )
      }
    }
  }, [smallerInstallment])

  return (
    <>
      {Object.keys(smallerInstallment).length > 0 && (
        <div className="container-gradient p-3 p-xl-5 rounded-3 w-75 mx-auto position-relative top-50 translate-middle-y text-center">
          <div className="menor-parcela p2-5 mb-4 mx-auto">
            <div className="w-100 w-md-75 label-checked rounded-pill border p-1 mx-auto my-1 my-md-3 font-custom">
              Menor parcela encontrada
            </div>
            <p className="h3 mt-2 p-1">{minValue}</p>
          </div>
          <div>
            <span className="text-muted">Prazo</span>
            <p className="h3 mt-2">
              {smallerInstallment.prazo} meses
            </p>
          </div>
          <div>
            <span className="text-muted">Crédito</span>
            <p className="h3 mt-2">
              {smallerInstallment.loanValue.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </p>
          </div>
          <div>
            <span className="text-muted">Valor do imóvel</span>
            <p className="h3 mt-2">{propertyValue}</p>
          </div>
        </div>
      )}
    </>
  );
}
