import React from 'react'
import ReactDOM from 'react-dom/client'
import { GlobalContext } from './modules/context'
import './assets/css/simulador.css'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <GlobalContext />
  </React.StrictMode>
)
