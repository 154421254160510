import snakecaseKeys from "snakecase-keys";
import Swal from "sweetalert2";
import { APICREDHABITARLEADS, SENDEMAIL } from "../../../api";

export const onSubmitUserInfo = async (userInfo, history, id, processList, parameters, productType) => {
  let userData = {};
  let simulationData = {};

  let localSimulationFI = JSON.parse(localStorage.getItem("@SimulationFI"));
  let localSimulationCGI = JSON.parse(localStorage.getItem("@SimulationCGI"));

  userData.name = userInfo.name;
  userData.phonenumber = userInfo.phonenumber;
  userData.email = userInfo.email;

  userData.phonenumber = userData.phonenumber.replace(" ", "");
  if (localSimulationFI && processList[0].productType === "aqu") {
    simulationData = { ...localSimulationFI };
    simulationData.tpl_category_id = 1;
  } else {
    simulationData = { ...localSimulationCGI };
    simulationData.tpl_category_id = 2;
  }

  
  simulationData.simulations_result_list = [];
  
  for (let process of processList) {
    simulationData.simulations_result_list.push({
      "bank": process["bank"],
      "first_installment": process["installments"][0]["installment"],
      "last_installment": process["installments"][process["installments"].length - 1]["installment"],
      "minimum_income": process["minimumIncome"],
      "public_fee": process["publicFee"],
      "subtitle": process["subtitle"],
      "amortizationType": process["amortizationType"],
    });
  }

  simulationData = snakecaseKeys(simulationData);

  try {
    await SENDEMAIL.post("", { ...userData, ...simulationData });

    await APICREDHABITARLEADS.post("", { ...userData, ...simulationData });
    
    Swal.fire({
      title: 'Obrigado!',
      text: 'As simulações foram enviadas para o seu email e um especialista entrará em contato em breve.',
      icon: 'success',
      confirmButtonText: 'Fechar',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-lg btn-danger',
      },
    }).then(() => {
      localStorage.removeItem('@SimulationFI');
      localStorage.removeItem('@SimulationCGI');
      localStorage.removeItem('@lastSimulation');
      history.push('/');
    });

  } catch (err) {
    Swal.fire({
      icon: "error",
      text: "Ooops... Desculpe. Já avisamos nossos técnicos.",
      title: "Algo deu errado!",
    });
  }
};
