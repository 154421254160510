import { useHistory } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export const NotFoundPage = () => {
  const [timer, setTimer] = useState(5)
  const history = useHistory()
  useEffect(() => {
    const interval = setInterval(() => {
      if (timer == 0) {
        history.push('/')
        return () => clearInterval(interval)
      }
      if (timer > 0) {
        return setTimer(timer - 1)
      }
      
    }, 1000)
    return () => clearInterval(interval)
  })

  return (
    <Container className='my-auto'>
      <Row>
        <Col className="d-flex flex-column align-items-center justify-content-center">
          <h1>Opss... Parece que você entrou em uma página vazia :(</h1>
          <p>
            Voce sera redirecionado em {timer}{' '}
            {timer > 1 ? 'segundos' : 'segundo'}
          </p>
        </Col>
      </Row>
    </Container>
  )
}
