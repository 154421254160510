export const phoneMask = (e) => {
  let inputValue = e.target.value;

  if (inputValue.length >= 14) {
    inputValue = inputValue
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "($1) $2")
      .replace(/(\d{5})(\d{4})/, "$1-$2");

    e.target.value = inputValue;

    return e;
  }

  inputValue = inputValue
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{4})(\d{4})/, "$1-$2");

  e.target.value = inputValue;

  return e;
};
