import camelcaseKeysDeep from 'camelcase-keys-deep'
import { createContext, useEffect, useState } from 'react'
import { APIVALIDATION } from '../api'

export const ValidationContext = createContext({})

const ValidationProvider = ({ children }) => {
  const [validationFI, setValidationFI] = useState(null)
  const [validationCGI, setValidationCGI] = useState(null)


  useEffect(() => {
    APIVALIDATION.get('').then(({data}) => {
      setValidationFI(camelcaseKeysDeep(data[0]))
      setValidationCGI(camelcaseKeysDeep(data[1]))
    })
  }, [])
  
  return (
    <ValidationContext.Provider value={{ validationCGI, validationFI }}>
      {children}
    </ValidationContext.Provider>
  )
}
export default ValidationProvider
