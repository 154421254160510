export const userValidate = async (schemaValues) => {
  let errors = {};

  const regexEmail =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  /* set up content for validate */

  if (!schemaValues.name) {
    errors.name = "Nome é obrigatorio";
  }

  if (!regexEmail.test(schemaValues.email)) {
    errors.email = "Email inválido";
  }

  if (!schemaValues.email) {
    errors.email = "Email é obrigatorio";
  }

  if (!schemaValues.phonenumber) {
    errors.phonenumber = "Email inválido";
  }

  if (schemaValues.phonenumber.length < 13) {
    errors.phonenumber = "Telefone inválido";
  }

  return errors;
};
