import { useFormik } from 'formik'
import { useContext, useEffect, useRef, useState } from 'react'
import { ErrorMessage } from '../common/components/errorMessage'
import { currencyMask } from '../common/utils/currencyInput'
import { validate } from './validation/validationCGI'
import { onSubmit } from './onSubmit'
import { useHistory } from 'react-router-dom'
import { ProcessContext } from '../context/processContext'
import { dateMask } from '../common/utils/dateMaskInput'
import { formatMoney } from '../common/utils/format/formatMoney'
import { formatDate2 } from '../common/utils/format/formatDate'
import { motion } from 'framer-motion'
import { appearAnimation } from '../common/animations/surger'
import { Row, Col, Button } from 'react-bootstrap'
import { numberInput } from '../common/utils/numberInput'
import Swal from 'sweetalert2'
import { ValidationContext } from '../context/validationContexts'

export const CGIForm = ({ simulationType, simulation, setLastSimulation }) => {
  const [terms, setTerms] = useState(false)
  const { setProcessList } = useContext(ProcessContext)
  const { validationCGI } = useContext(ValidationContext)

  const [inputPrice, setInputPrice] = useState('')
  const [inputLoanValue, setLoanValue] = useState('')
  const [inputDate, setInputDate] = useState('')
  const [inputMonth, setInputMonth] = useState('')
  const url = window.location.search
  
  const history = useHistory()
  const textInput = useRef(null)

  useEffect(() => {
    setTimeout(() => {
      textInput.current?.focus()
    }, 0)

    if (simulation && Object.keys(simulation).length > 0) {
      setInputPrice(formatMoney(simulation.propertyValue))
      setLoanValue(formatMoney(simulation.loanValue))
      setInputDate(formatDate2(simulation.birthDate))

      setInputMonth(simulation.timeMonths)
    }
  }, [])
  let initialValues = { ...simulation }

  if (Object.keys(initialValues).length === 0) {
    //
    initialValues.categoryId = simulationType
    initialValues.propertyValue = inputPrice
    initialValues.loanValue = inputLoanValue
    initialValues.birthDate = inputDate
    initialValues.timeMonths = inputMonth
    initialValues.parameters = ''
    if(url){
      initialValues.parameters = url
    }
    //
  }

  const formik = useFormik({
    initialValues,
    validate: (values)=>{
      return validate(values, validationCGI)
    },
    onSubmit: (values) => {
      onSubmit(values, history, setProcessList, setLastSimulation)
    },
  })

  return (
    <motion.form
      {...appearAnimation}
      className="row"
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault()
        if (Object.keys(formik.errors).length > 0) {
          Swal.fire({
            icon: 'warning',
            title: 'Opss...',
            text: 'Preencha os campos corretamente!',
          })
        }
        if (terms) {
          return formik.handleSubmit(e)
        } else window.alert('Aceite os termos para continuar')
      }}
    >
      <div className="d-flex flex-column align-items-center my-4">
        <label>
          <h5 className="text-center">Qual o valor do seu imóvel?</h5>
        </label>
        <input
          className={`form-control p-3 w-100 bg-none rounded-pill mt-2 ${
            formik.errors.propertyValue ? 'input-error' : ''
          }`}
          placeholder="R$"
          name="propertyValue"
          value={inputPrice}
          onChange={(e) => {
            const event = currencyMask(e)
            formik.handleChange(currencyMask(e))

            setInputPrice(event.target.value)
          }}
          ref={textInput}
          autoFocus
        />
        {formik.errors.propertyValue && (
          <ErrorMessage>{formik.errors.propertyValue}</ErrorMessage>
        )}
      </div>

      <div className="d-flex flex-column align-items-center my-4">
        <label>
          <h5 className="text-center">Qual valor do empréstimo?</h5>
        </label>
        <input
          className={`form-control p-3 w-100 bg-none rounded-pill mt-2 ${
            formik.errors.loanValue ? 'input-error' : ''
          }`}
          name="loanValue"
          placeholder="R$"
          value={inputLoanValue}
          onChange={(e) => {
            const event = currencyMask(e)
            formik.handleChange(currencyMask(e))

            setLoanValue(event.target.value)
          }}
        />
        {formik.errors.loanValue && (
          <ErrorMessage>{formik.errors.loanValue}</ErrorMessage>
        )}
      </div>

      <div className="d-flex flex-column align-items-center my-4">
        <label>
          <h5 className="text-center">Sua data de nascimento:</h5>
        </label>
        <input
          className={`form-control p-3 w-100 bg-none rounded-pill mt-2 ${
            formik.errors.birthDate ? 'input-error' : ''
          }`}
          name="birthDate"
          placeholder="01/01/1991"
          value={inputDate}
          onChange={(e) => {
            if (e.target.value.length <= 10) {
              const event = dateMask(e)
              setInputDate(event.target.value)
              formik.handleChange(dateMask(e))
            }
          }}
        />
        {formik.errors.birthDate && (
          <ErrorMessage>{formik.errors.birthDate}</ErrorMessage>
        )}
      </div>

      <div className="d-flex flex-column align-items-center my-4">
        <label>
          <h5 className="text-center">Prazo do financiamento (em meses):</h5>
        </label>
        <input
          className={`form-control p-3 w-100 bg-none rounded-pill mt-2 ${
            formik.errors.timeMonths ? 'input-error' : ''
          }`}
          name="timeMonths"
          placeholder="Ex: 240"
          value={inputMonth}
          onChange={(e) => {
            const event = numberInput(e)
            setInputMonth(event.target.value)
            formik.handleChange(numberInput(event))
          }}
        />
        {formik.errors.timeMonths && (
          <ErrorMessage>{formik.errors.timeMonths}</ErrorMessage>
        )}
      </div>

      <div className="col-12 lh-lg my-4 text-center">
        <label
          className="w-100"
          role="button"
          htmlFor="cgi-terms"
        >
          <input
            type="checkbox"
            name="terms"
            id="cgi-terms"
            className="me-3"
            onChange={() => {
              return setTerms(!terms)
            }}
            checked={terms}
          />
            Li e aceito os
            
        </label>
        <a href="https://credihabitar.com.br/politica-privacidade/#termo-uso" target={'_blank'} className="d-block d-md-inline">termos de uso</a> e a {' '}
        <a href="https://credihabitar.com.br/politica-privacidade/" target={'_blank'} className="d-block d-md-inline">política de privacidade</a>
      </div>
      
      <div className="d-grid d-md-block gap-2">
        <Button size="lg" variant="custom"type="submit" className="rounded-pill w-100 px-5 mx-auto">
          Simular agora
        </Button>
      </div>
    </motion.form>
  )
}
