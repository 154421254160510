import Cookies from "js-cookie";
import { createContext, useEffect, useState } from "react";

export const SimulationContext = createContext({
  reload: false,
});

const SimulationProvider = ({ children }) => {
  const [simulationFI, setSimulationFI] = useState();
  const [simulationCGI, setSimulationCGI] = useState();
  const [lastSimulation, setLastSimulation] = useState();

  useEffect(() => {
    const resetForm = Cookies.get("simulation");

    if (!resetForm) {
      localStorage.removeItem("@SimulationFI");
      localStorage.removeItem("@SimulationCGI");
      localStorage.removeItem("@savedSimulation");
    }

    const localSimulationFI = localStorage.getItem("@SimulationFI");
    if (localSimulationFI) {
      setSimulationFI(JSON.parse(localSimulationFI));
    }
    const localSimulationCGI = localStorage.getItem("@SimulationCGI");
    if (localSimulationCGI) {
      setSimulationCGI(JSON.parse(localSimulationCGI));
    }
    const lastSimulation = localStorage.getItem("@lastSimulation");
    if (lastSimulation) {
      setLastSimulation(JSON.parse(lastSimulation));
    }
  }, []);

  return (
    <SimulationContext.Provider
      value={{
        simulationFI,
        setSimulationFI,
        simulationCGI,
        setSimulationCGI,
        lastSimulation,
        setLastSimulation,
      }}
    >
      {children}
    </SimulationContext.Provider>
  );
};
export default SimulationProvider;
