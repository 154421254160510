export const dateMask = (e) => {
  let value = e.target.value;
  value = value.replace(/\D/g, "");
  value = value
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d{4})/, "$1/$2");

  e.target.value = value;
  return e;
};
