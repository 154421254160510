import camelcaseKeysDeep from "camelcase-keys-deep";
import { APICREDHABITAR, APICREDHABITARLEADS } from "../api";
import { formatDate } from "../common/utils/format/formatDate";

export const onSubmit = async (
  dataSubmit,
  history,
  setProcessList,
  setLastSimulation
) => {
  const user = localStorage.getItem("@UserCreated");
  let sendData = {};
  // Formating data to send for api
  let birthDate = dataSubmit.birthDate;
  if (birthDate.includes("/")) {
    birthDate = formatDate(birthDate);
  }

  let loanValueFormatted = String(dataSubmit.loanValue)
    .replace("R$", "")
    .replaceAll(".", "")
    .replace(",", ".");

  let propertyValueFormatted = String(dataSubmit.propertyValue)
    .replace("R$", "")
    .replaceAll(".", "")
    .replace(",", ".");

  let months = Number(dataSubmit.timeMonths);

  propertyValueFormatted = Number(propertyValueFormatted);
  loanValueFormatted = Number(loanValueFormatted);

  // Filling data of object to send
  sendData.property_value = propertyValueFormatted;
  sendData.loan_value = loanValueFormatted;
  sendData.is_finance_documentation = false;
  sendData.is_finance_analysis_and_ratings = false;
  sendData.time_months = months;
  sendData.birth_date = birthDate;
  sendData.category_id = dataSubmit.categoryId;

  if (dataSubmit.searchProperty) {
    sendData.has_defined_property =
      dataSubmit.searchProperty.length > 0 ? 1 : 0;
  } else {
    sendData.has_defined_property = 0;
  }

  if (dataSubmit.categoryId === 1) {
    localStorage.setItem(
      "@SimulationFI",
      JSON.stringify(camelcaseKeysDeep(sendData))
    );
    localStorage.setItem("@lastSimulation", JSON.stringify(1));
    setLastSimulation(1);
  } else {
    localStorage.setItem(
      "@SimulationCGI",
      JSON.stringify(camelcaseKeysDeep(sendData))
    );
    localStorage.setItem("@lastSimulation", JSON.stringify(2));
    setLastSimulation(2);
  }


  return await APICREDHABITAR.post("", sendData).then(({ data }) => {
    setProcessList(camelcaseKeysDeep(data));
    let userId = '' // user? data[0].pk_hash : ''; //dataSubmit.parameters;   //pk_hash
    history.push(`/process/${userId}`);
  });
};
