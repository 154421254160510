import { useContext, useEffect, useState } from 'react'
import { CGIForm } from './formCgi'
import { FIForm } from './formFinanci'
import { SimulationContext } from '../context/simulationContext'
import { AnimatePresence, motion } from 'framer-motion'
import { animationDownToCenter } from '../common/animations/downToCenter'
import classNames from 'classnames'
import { Col, Row } from 'react-bootstrap'

export const SimulationForm = () => {
  const { simulationFI, simulationCGI, lastSimulation, setLastSimulation } =
    useContext(SimulationContext)

  const [simulationType, setSimulationType] = useState('')

  useEffect(() => {
    if (simulationFI && lastSimulation && lastSimulation === 1) {
      setSimulationType(1)
    }
  }, [simulationFI])

  useEffect(() => {
    if (simulationCGI && lastSimulation && lastSimulation === 2) {
      setSimulationType(2)
    }
  }, [simulationCGI])

  const backgroundStyle = {
    position: 'relative',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  };
  
  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: `url("/img/fundo-home.png")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    opacity: 0.1
  };


  return (
    <main className="container-fluid">
      <div style={backgroundStyle}>
      <div style={overlayStyle}></div>

        <Row className="vh-100 position-relative">
          <Col md={6} className="ps-0 position-fixed h-100 d-flex justify-content-center align-items-center text-center" id="hero">
            <div className="px-5 pt-5">
              <img
                className="mb-5 log"
                src="/img/logo-ch.svg"
                alt="Credihabitar plataforma de financiamento imobiliario"
                height={40}
              />
              <p className="text-muted d-none d-md-block fs-4">
                Nós buscamos, comparamos e entregamos as <span class="fw-bold">melhores condições</span> dos
                principais bancos privados <span class="fw-bold">para você</span>.
              </p>
            </div>
          </Col>

          <motion.div
            className="col-md-6 ms-auto px-0"
            id="simulador"
            {...animationDownToCenter}
          >
            <Col md={10} className="h-100 p-2 p-xl-5 mx-auto d-flex justify-content-start media-home">

              <div
                className="container-gradient container-simulation container-home p-3 p-xl-5 rounded-3 position-relative text-center"
              >
                <h2
                  className="fw-semibold p-5 head-text"
                >
                  Simule aqui seu{" "}
                  <span className="font-custom">financiamento imobiliário</span> ou
                  empréstimo com{" "}
                  <span className="font-custom">garantia de imóvel</span>.
                </h2>
                <h5 className="mt-4 text-center">Qual o tipo de financiamento?</h5>

                <div className="d-flex flex-column w-100 my-4">
                  <label
                    htmlFor="radio2"
                    onClick={() => setSimulationType(1)}
                    className={classNames(
                      "simulation-type d-flex justify-content-start align-items-center rounded-pill p-3 mb-3",
                      simulationType === 1 ? "label-checked" : ""
                    )}
                  >
                    <div className="circle mx-3" />
                    <span className="text-truncate">Financiamento imobiliário</span>
                  </label>
                  <label
                    htmlFor="radio1"
                    onClick={() => setSimulationType(2)}
                    className={classNames(
                      "simulation-type d-flex justify-content-start align-items-center rounded-pill p-3 text-truncate",
                      simulationType === 2 ? "label-checked" : ""
                    )}
                  >
                    <div className="circle mx-3"></div>
                    <span className="text-truncate">
                      Crédito com garantia imobiliária
                    </span>
                  </label>
                </div>

                <AnimatePresence>
                  {simulationType === 1 && (
                    <FIForm
                      simulationType={simulationType}
                      simulation={simulationFI}
                      setLastSimulation={setLastSimulation}
                    />
                  )}
                  {simulationType === 2 && (
                    <CGIForm
                      simulationType={simulationType}
                      simulation={simulationCGI}
                      setLastSimulation={setLastSimulation}
                    />
                  )}
                </AnimatePresence>
              </div>
            </Col>
          </motion.div>
        </Row>

      </div>
    </main>

  )
}
