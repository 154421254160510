import axios from "axios"
import { Process } from "../process/resultadoSimplificado"

const token = process.env.REACT_APP_COOKIE_AUTH_KEY

export const APIVALIDATION = axios.create({
  baseURL: process.env.REACT_APP_SIMULATION_API_URL + "/simulations/products",
  headers: { Authorization: `Bearer ${token}`,
            'Cache-Control': 'no-cache' },
})

export const APICREDHABITAR = axios.create({
  baseURL: process.env.REACT_APP_SIMULATION_API_URL + "/simulations/simulate",
  headers: { Authorization: `Bearer ${token}`,
            'Cache-Control': 'no-cache' },
})


export const APICREDHABITARLEADS = axios.create({
  baseURL: process.env.REACT_APP_SIMULATION_API_URL + "/leads/",
  headers: { Authorization: `Bearer ${token}`,
            'Cache-Control': 'no-cache' },
})

export const SENDEMAIL = axios.create({
  // baseURL: process.env.EMAIL_API_URL + "/send_email",
  baseURL: "https://api.simulador.portalcredihabitar.com.br/send_email",
  headers: { 
    // Authorization: `Bearer ${process.env.EMAIL_API_TOKEN}`
    Authorization: `Bearer d2c12bf0-c702-4d62-9d5c-74f03b6a471d`
  },
})