export const formatDate = (dateDDMMYYYY) => {
  const date = dateDDMMYYYY;
  const formatedDate = date.split("/").reverse().join("-");
  return formatedDate;
};

export const formatDate2 = (dateYYYYMMDD) => {
  const date = dateYYYYMMDD;
  const formatedDate = date.split("-").reverse().join("/");
  return formatedDate;
};
