import { formatDate } from '../../common/utils/format/formatDate'
import { formatMoney } from '../../common/utils/format/formatMoney'
import { getAge } from '../../common/utils/getAge'

const calculateMonthsUntil80 = (birthDateString) => {
  const [day, month, year] = birthDateString.split('/').map(Number);
  const birthDate = new Date(year, month - 1, day);
  const targetDate = new Date(birthDate);
  targetDate.setFullYear(targetDate.getFullYear() + 80);
  const currentDate = new Date();

  let months;
  months = (targetDate.getFullYear() - currentDate.getFullYear()) * 12;
  months -= currentDate.getMonth();
  months += targetDate.getMonth();
  if (currentDate.getDate() > targetDate.getDate()) {
    months -= 1;
  }
  return months;
}

export const validate = async (schemaValues, validationCGI) => {
  let errors = {}
  /* set up content for valibirthDate */

  let loanValueFormatted = String(schemaValues.loanValue)
    .replace('R$', '')
    .replaceAll('.', '')
    .replace(',', '.')

  let propertyValueFormatted = String(schemaValues.propertyValue)
    .replace('R$', '')
    .replaceAll('.', '')
    .replace(',', '.')

  let maxLoanValue = propertyValueFormatted * validationCGI.maxLoanPercent

  propertyValueFormatted = Number(propertyValueFormatted)
  loanValueFormatted = Number(loanValueFormatted)
  // const ageInNumber = getAge(formatDate(schemaValues.birthDate));

  let ageInNumber = schemaValues.birthDate

  if (ageInNumber.includes('/')) {
    ageInNumber = getAge(formatDate(ageInNumber))
  } else {
    ageInNumber = getAge(ageInNumber)
  }

  let months = Number(schemaValues.timeMonths)

  /* Validations  */
  if (!schemaValues.loanValue) {
    errors.loanValue = 'Esse campo é obrigátorio'
  }

  if (!schemaValues.propertyValue) {
    errors.propertyValue = 'esse campo é obrigátorio'
  }

  if (!schemaValues.timeMonths) {
    errors.timeMonths = 'esse campo é obrigátorio'
  }

  if (!schemaValues.birthDate) {
    errors.birthDate = 'esse campo é obrigátorio'
  }

  if (propertyValueFormatted < validationCGI.minGoodPrice) {
    const formatted = formatMoney(validationCGI.minGoodPrice)
    errors.propertyValue = `O valor mínimo exigido é ${formatted}`
  }

  if (propertyValueFormatted > validationCGI.maxGoodPrice) {
    const formatted = formatMoney(validationCGI.maxGoodPrice)
    errors.propertyValue = `O valor mínimo exigido é ${formatted}`
  }

  if (loanValueFormatted > maxLoanValue) {
    const formatted = formatMoney(maxLoanValue)
    const porcentageNumber = 100
    errors.loanValue = `Valor acima de ${
      validationCGI.maxLoanPercent * porcentageNumber
    }% do imóvel, valor máximo ${formatted}`
  }

  if (loanValueFormatted < validationCGI.minLoanValue) {
    const formatted = formatMoney(validationCGI.minLoanValue)
    errors.loanValue = `Minimo de ${formatted}`
  }

  if (ageInNumber < 18) {
    errors.birthDate = 'A idade mínima é de 18 anos'
  }

  if (ageInNumber > 79) {
    errors.birthDate = 'A idade máxima é de 79 anos'
  }

  if (isNaN(ageInNumber)) {
    errors.birthDate = 'Data inválida (dd/mm/yyyy)'
  }
  if (schemaValues.birthDate.length < 10) {
    errors.birthDate = 'Data inválida (dd/mm/yyyy)'
  }

  let faltaTo80 = calculateMonthsUntil80(schemaValues.birthDate)
  let maxMesesToFinance = Math.min(validationCGI.maxTimeMonths, faltaTo80)

  if (months > maxMesesToFinance) {
    errors.timeMonths = `Conforme data informada, o prazo máximo é de ${maxMesesToFinance} meses`;
  }

  if (months < validationCGI.minTimeMonths) {
    errors.timeMonths = `Mínimo de ${validationCGI.minTimeMonths} meses`
  }

  return errors
}
